import React from 'react';

function About (props) {
    return (
        <div>
            About
        </div>
    )
}

export default About;